import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getScoreCoefficents = createAsyncThunk("scoreCoefficents", async () => {
  const response = await apiClient.get("score_coefficents");
  return response.data;
});

export const getScoreCoefficentById = createAsyncThunk("scoreCoefficent/id", async (id) => {
  const response = await apiClient.get(`score_coefficent/${id}`);
  return response.data;
});

export const deleteScoreCoefficentById = createAsyncThunk("scoreCoefficent/delete", async (id) => {
  const response = await apiClient.delete(`score_coefficent/${id}`);
  return response.data;
});

export const addScoreCoefficent = createAsyncThunk("scoreCoefficents/add", async (scoreCoefficent) => {
  const response = await apiClient.post("score_coefficents", scoreCoefficent);

  return response.data;
});

export const updateScoreCoefficentById = createAsyncThunk(
  "scoreCoefficent/id",
  async ({ id, data }) => {
    const response = await apiClient.put(`score_coefficent/${id}`, data);
    return response.data;
  }
);

export const getLastScoreCoefficent = createAsyncThunk("getLastScoreCoefficent", async () => {
  const response = await apiClient.get("last_score_coefficent");
  return response.data;
});